/* eslint-disable import/no-default-export */
import React from 'react';
import { Helmet } from 'react-helmet';
import { Layout } from '../components/Layout';
import { HeroAboutUs } from '../img/svg/HeroAboutUs';
import { HowToChooseInsurerImage } from '../img/svg/HowToChooseInsurerImage';

export default () => (
  <>
    <Helmet title="O nas | swiatubezpieczen.com" />
    <Layout>
      <section id="hero" className="section section--about-us-hero-image">
        <div className="wrapper">
          <div className="wrapper">
            <HeroAboutUs />
          </div>
        </div>
      </section>
      <section id="birth" className="section section--lightblue">
        <div className="wrapper">
          <div className="row row--col-reverse row--flex">
            <div className="row__large-6 row__large--offset-1">
              <span className="heading-short heading-short--no-padding">Jak powstał</span>
              <h2 className="heading">Świat Ubezpieczeń </h2>
              <p className="paragraph">Świat Ubezpieczeń to połączenie rzetelnej wiedzy i pasji osób, które uznawane są za najlepszych specjalistów w branży ubezpieczeń. Pomysł stworzenia Świata Ubezpieczeń był kolejnym, w pełni naturalnym krokiem – wynikał przede wszystkim z chęci podzielenia się zgromadzoną przez lata wiedzą, dotyczącą różnych rodzajów ubezpieczeń. W naszym gronie znajdują się najlepsi eksperci, specjalizujący się przede wszystkim w ubezpieczeniach komunikacyjnych, ale też w ubezpieczeniach na życie, polisach mieszkaniowych czy turystycznych. Świat Ubezpieczeń narodził się dlatego, że ubezpieczenia nie mają dla nas tajemnic – i dzisiaj chcemy dzielić się tą wiedzą, aby móc ułatwić wybór najlepszej polisy każdemu, kto chce zapewnić ochronę sobie i swojej rodzinie. </p>
            </div>
            <div className="row__large-5">
              <div className="image-wrapper image-wrapper--right">
                <HowToChooseInsurerImage />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="insurance-details" className="section section--insurance-details section--border-bottom">
        <div className="wrapper">
          <span className="heading-short heading-short--no-padding">Dowiedz się</span>
          <h2 className="heading heading--about-insurance">Dlaczego ubezpieczenie jest tak ważne?</h2>
          <div className="row row--gutters">
            <div className="row__large-6">
              <p className="paragraph">W Świecie Ubezpieczeń doskonale zdajemy sobie sprawę, jak ważne jest ubezpieczenie. Dotyczy to nie tylko ubezpieczenia komunikacyjnego, czyli OC i AC, ale też polis turystycznych czy ubezpieczenia na życie. Odpowiednio dobrana polisa zapewnia ochronę na wypadek wielu różnych sytuacji. Jest to dobra inwestycja, która może zagwarantować nie tylko bezpieczeństwo, ale też – co równie ważne – spokój o siebie i swoją rodzinę.</p>
              <p className="paragraph">Nie bez powodu coraz więcej osób decyduje się na zakup dodatkowego ubezpieczenia, w szczególności polisy na życie. Sprawdzi się ona w wielu sytuacjach, na przykład w razie choroby. Warto zakupić ubezpieczenie nie tylko dla siebie, ale też dla swoich bliskich – współmałżonka, dzieci czy rodziców.</p>
            </div>
            <div className="row__large-6">
              <p className="paragraph">Osoby, które często podróżują, z pewnością zdają sobie sprawę, jak ważne jest ubezpieczenie turystyczne. Jeżeli regularnie wyjeżdżasz za granicę, za każdym razem powinieneś wykupić polisę. W większości przypadków na ubezpieczenie turystyczne składa się kilka elementów, między innymi ubezpieczenie zdrowotne, NNW, OC w życiu prywatnym czy ubezpieczenie bagażu. Co ważne, jego koszt często nie przekracza kwoty kilku złotych na dzień – a zatem polisa nie jest dużym wydatkiem. Korzyści z jej posiadania mogą być ogromne – kiedy w czasie wyjazdu będziesz musiał skorzystać z pomocy lekarza, nie będziesz musiał płacić wysokich rachunków za skorzystanie z usługi medycznej. Jeśli jeszcze nie posiadasz ubezpieczenia, najwyższa pora to zmienić! </p>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  </>
);
